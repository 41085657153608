var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.shared.formatting.internal.numbers.js");require("./metabase.shared.formatting.internal.numbers_core.js");require("./metabase.util.js");
'use strict';var qsa=function(a,b){return new $CLJS.ie(null,function(){a:for(var c=a,d=b;;){d=$CLJS.y(d);var e;if(e=d)e=$CLJS.z(d),e=c.h?c.h(e):c.call(null,e);if($CLJS.n(e))d=$CLJS.Hc(d);else break a}return d},null,null)},rsa=function(a,b){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rt(a,b),qsa(a,b)],null)},T4=function(a){var b=$CLJS.mk.l;var c=$CLJS.O(a);var d=$CLJS.J.g(c,$CLJS.M4);c=$CLJS.J.g(c,$CLJS.N4);if($CLJS.n($CLJS.n(d)?$CLJS.E.g(c,"currency"):d)){c=$CLJS.H4.h;d=$CLJS.mh.h(d);var e=$CLJS.q($CLJS.E4);
d=e.h?e.h(d):e.call(null,d);c=c.call($CLJS.H4,d);c=new $CLJS.h(null,2,[$CLJS.L4,c,$CLJS.O4,c],null)}else c=new $CLJS.h(null,1,[$CLJS.O4,2],null);b=b.call($CLJS.mk,$CLJS.H([c,a]));return $CLJS.n($CLJS.P4.h(a))?$CLJS.R.l($CLJS.Nj.g(b,$CLJS.P4),$CLJS.O4,$CLJS.P4.h(a),$CLJS.H([$CLJS.L4,$CLJS.P4.h(a)])):b},U4=function(a,b){if(null!=a&&null!=a.Oe)a=a.Oe(a,b);else{var c=U4[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=U4._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.$a("NumberFormatter.format-number-basic",
a);}return a},ssa=function(a,b){var c=$CLJS.Ly(a.formatToParts(b),$CLJS.H([new $CLJS.h(null,1,[$CLJS.gi,!0],null)]));a=$CLJS.n($CLJS.Me(function(d){return $CLJS.E.g($CLJS.Mi.h(d),"exponentMinusSign")},c))?c:function(){var d=rsa(function(f){return $CLJS.Tj.g($CLJS.Mi.h(f),"exponentInteger")},c),e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.bf.l(e,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.Mi,"exponentPlusSign",$CLJS.$i,"+"],null)],null),$CLJS.H([d]))}();return $CLJS.Ne.g($CLJS.p,
$CLJS.cf.g(function(d){var e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.Mi);e=$CLJS.J.g(e,$CLJS.$i);switch(d){case "exponentSeparator":return"e";default:return e}},a))},tsa=function(a,b){switch($CLJS.N4.h(b)){case "scientific":return function(c){return ssa(a,c)};default:return function(c){return a.format(c)}}},V4=function(a,b,c,d,e){this.options=a;this.Bg=b;this.Eg=c;this.de=d;this.li=e;this.C=393216;this.I=0},usa=function(a,b){return $CLJS.Ne.g($CLJS.p,function(){return function e(d){return new $CLJS.ie(null,
function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.vd(f)){var k=$CLJS.ic(f),l=$CLJS.D(k),m=$CLJS.le(l);return function(){for(var v=0;;)if(v<l){var x=$CLJS.hd(k,v),A=$CLJS.O(x);x=$CLJS.J.g(A,$CLJS.Mi);A=$CLJS.J.g(A,$CLJS.$i);var C=new $CLJS.Rg(null,new $CLJS.h(null,2,["currency",null,"integer",null],null),null);C=C.h?C.h(x):C.call(null,x);$CLJS.n(C)&&(x=$CLJS.E.g(x,"integer")?b:A,m.add(x));v+=1}else return!0}()?$CLJS.oe($CLJS.qe(m),e($CLJS.jc(f))):$CLJS.oe($CLJS.qe(m),null)}var t=$CLJS.z(f);t=$CLJS.O(t);
var u=$CLJS.J.g(t,$CLJS.Mi);t=$CLJS.J.g(t,$CLJS.$i);if($CLJS.n(function(){var v=new $CLJS.Rg(null,new $CLJS.h(null,2,["currency",null,"integer",null],null),null);return v.h?v.h(u):v.call(null,u)}()))return $CLJS.ae($CLJS.E.g(u,"integer")?b:t,e($CLJS.Hc(f)));f=$CLJS.Hc(f)}else return null},null,null)}($CLJS.Ly(a.Bg.formatToParts(1),$CLJS.H([$CLJS.gi,!0])))}())},W4=function(a){var b=$CLJS.E.g($CLJS.N4.h(a),"currency")?2:null;b=new Intl.NumberFormat("en",$CLJS.ok($CLJS.Jz($CLJS.Sa,$CLJS.zg([$CLJS.nsa,
$CLJS.fsa,$CLJS.isa,$CLJS.ksa,$CLJS.psa,$CLJS.M4,$CLJS.jsa,$CLJS.lsa,$CLJS.gsa,$CLJS.hsa],[$CLJS.O4.g(a,b),$CLJS.R4.h(a),$CLJS.Q4.h(a),$CLJS.msa.h(a),$CLJS.E.g($CLJS.N4.h(a),"scientific")?null:$CLJS.N4.g(a,"decimal"),$CLJS.M4.h(a),!0,$CLJS.L4.g(a,b),$CLJS.E.g($CLJS.N4.h(a),"scientific")?"scientific":null,$CLJS.S4.h(a)]))));var c=$CLJS.R4.h(a);var d=$CLJS.M4.h(a);c=$CLJS.n(d)?null==c||$CLJS.E.g(c,"symbol"):d;d=tsa(b,a);return new V4(a,b,c,d,$CLJS.N)},X4=function(a,b){return W4(T4(b)).Oe(null,a)},Z4=
function(a,b){var c=$CLJS.Nj.l(b,$CLJS.AA,$CLJS.H([$CLJS.N4])),d=$CLJS.yz(a);if(0===a)return"0";if(1E3>d)return $CLJS.Y4.g?$CLJS.Y4.g(a,c):$CLJS.Y4.call(null,a,c);b=$CLJS.z($CLJS.ff(function(f){return d>=$CLJS.z(f)},vsa));var e=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return[$CLJS.p.h(function(){var f=a/e,k=$CLJS.mk.l($CLJS.H([c,new $CLJS.h(null,2,[$CLJS.L4,1,$CLJS.O4,1],null)]));return $CLJS.Y4.g?$CLJS.Y4.g(f,k):$CLJS.Y4.call(null,f,k)}()),$CLJS.p.h(b)].join("")},wsa=function(a,b){var c=T4(b);if($CLJS.n($4.h(c)))b=
$4.h(c);else if($CLJS.Ra($CLJS.P4.h(c))&&$CLJS.Ra($CLJS.L4.h(c))&&$CLJS.Tj.g($CLJS.N4.h(c),"currency")&&$CLJS.yz(a)<($CLJS.E.g($CLJS.N4.h(c),"percent")?.01:1)){b=$CLJS.R.j;var d=$CLJS.Nj.g(c,$CLJS.O4);c=$CLJS.S4.g(c,0);b=W4(b.call($CLJS.R,d,$CLJS.Q4,2>c?2:c))}else b=W4(c);return U4(b,a)};V4.prototype.P=function(a,b){return new V4(this.options,this.Bg,this.Eg,this.de,b)};V4.prototype.O=function(){return this.li};
V4.prototype.Oe=function(a,b){a=this.de.h?this.de.h(b):this.de.call(null,b);var c=$CLJS.osa.h(this.options);$CLJS.n($CLJS.n(c)?$CLJS.Tj.g(c,".,"):c)&&(b=$CLJS.z(c),c=$CLJS.dd(c),a=$CLJS.Gz(a,/[\.,]/,new $CLJS.h(null,2,[",",$CLJS.n(c)?c:"",".",b],null)));$CLJS.n(this.Eg)&&(b=$CLJS.M4.h(this.options),c=$CLJS.F4(b),a=$CLJS.Gz($CLJS.Gz(a,[$CLJS.Xg(b)," "].join(""),c),$CLJS.Xg(b),c));return a};
var $4=new $CLJS.M(null,"number-formatter","number-formatter",929788393),a5=new $CLJS.M(null,"scale","scale",-230427353),b5=new $CLJS.M(null,"negative-in-parentheses","negative-in-parentheses",-1676084141),xsa=new $CLJS.M(null,"scientific","scientific",316285837);var c5=new $CLJS.h(null,1,[$CLJS.R4,"symbol"],null),ysa=$CLJS.ok(c5),vsa=new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[1E12,"T"],null),new $CLJS.P(null,2,5,$CLJS.Q,[1E9,"B"],null),new $CLJS.P(null,2,5,$CLJS.Q,[1E6,"M"],null),new $CLJS.P(null,2,5,$CLJS.Q,[1E3,"k"],null)],null),d5=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.Ki,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.shared.formatting.numbers",
"format-number-compact*"),function(f,k){f=$CLJS.O(k);return $CLJS.J.g(f,$CLJS.N4)},e,a,b,c,d)}();d5.m(null,$CLJS.Oh,function(a,b){return Z4(a,b)});d5.m(null,"percent",function(a,b){return[$CLJS.p.h(Z4(100*a,b)),"%"].join("")});d5.m(null,"currency",function(a,b){b=$CLJS.mk.l($CLJS.H([b,c5]));var c=W4(b);return 1E3>$CLJS.yz(a)?c.Oe(null,a):usa(c,Z4(a,b))});d5.m(null,"scientific",function(a,b){return X4(a,$CLJS.mk.l($CLJS.H([b,new $CLJS.h(null,2,[$CLJS.O4,1,$CLJS.L4,1],null)])))});
$CLJS.Y4=function Y4(a,b){b=$CLJS.fA(b);var d=$CLJS.O(b);b=$CLJS.J.g(d,$CLJS.AA);var e=$CLJS.J.g(d,b5),f=$CLJS.J.g(d,$CLJS.N4),k=$CLJS.J.g(d,a5);return $CLJS.n($CLJS.n(k)?!isNaN(k):k)?(b=k*a,f=$CLJS.Nj.g(d,a5),Y4.g?Y4.g(b,f):Y4.call(null,b,f)):$CLJS.n(function(){var l=0>a;return l?e:l}())?["(",$CLJS.p.h(function(){var l=-a,m=$CLJS.R.j(d,b5,!1);return Y4.g?Y4.g(l,m):Y4.call(null,l,m)}()),")"].join(""):$CLJS.n(b)?d5.g(a,T4($CLJS.Nj.g(d,$CLJS.AA))):$CLJS.E.g($CLJS.mh.h(f),xsa)?X4(a,d):wsa(a,d)};
module.exports={compact_currency_options_js:ysa,format_number:$CLJS.Y4};